import {IMedialDimensions} from '../../types/app.types';

export const THUMBNAIL_DIMENSIONS: IMedialDimensions = {
  width: 100,
  height: 100,
};
export const MAX_PRODUCT_OPTION_LENGTH = 500;

export enum StyleParam {
  ShowOrderNumber = 'THANK_YOU_PAGE_ORDER_NUMBER_SELECTION',
  ShowImage = 'THANK_YOU_PAGE_IMAGE_SELECTION',
  ShowContinueBrowsingLink = 'THANK_YOU_PAGE_CONTINUE_BROWSING_LINK_SELECTION',
}

export const BI_APP_NAME = 'Stores';

export const BI_ORIGIN = 'thank you page';

export enum FedopsInteractions {
  GET_ORDER = 'wix-code-get-order',
}

export enum ProductType {
  Physical = 'physical',
  Digital = 'digital',
  Service = 'SERVICE',
}

export enum DeliveryType {
  SHIPPING = 'SHIPPING',
  PICKUP = 'PICKUP',
}

export const ORDER_FETCH_ATTEMPTS = 4;

export enum Specs {
  EnableNewTyp = 'specs.stores.UseNewTYP',
  EnablePlatformBiLoggerTYP = 'specs.stores.EnablePlatformBiLoggerTYP',
  EnableNewECOMBookingsTYP = 'specs.stores.EnableNewECOMBookingsTYP',
  NodeGqlFormattedOfflinePaymentBalance = 'specs.stores.NodeGqlFormattedOfflinePaymentBalance',
  FlexibleFrequencyInStorefront = 'specs.stores.ProductSubscriptionsFlexibleFrequencyInStorefront',
}

export const ADD_TO_CALENDAR_ACTION_ID = 'ADD_TO_CALENDAR';
